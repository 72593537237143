var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group properties-selector"},[_vm._l((_vm.propertiesPaymentsModel),function(item,key){return _c('div',{key:key,staticClass:"properties-wrapper"},[_c('div',{staticClass:"form-group-item type"},[_c('label',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t("PROPERTIES_PAYMENTS.PROPERTY"))+" ")]),(
          !_vm.filterProperty &&
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_PROPERTIES)
        )?_c('property-selector',{attrs:{"property":item.property.id?.toString(),"filterOrganization":_vm.filterOrganization,"onlyNotPaied":true},on:{"propertyChanged":(property) => {
            item.property.id = property.id;
            item.baseAmount = parseFloat(property.total-property.total_paied).toFixed(2);
            item.amount = item.amount == 0 ? parseFloat(property.total-property.total_paied).toFixed(2) : item.amount;
            _vm.propertiesPaymentsChanged();
            //item.restToPay = item.refund ? item.amount : ((property.total-property.total_paied)-item.amount).toFixed(2);
            //propertiesPaymentsChanged();
          }}}):_vm._e()],1),(item.amount > 0)?_c('div',{staticClass:"form-group-item type"},[_c('base-input',{attrs:{"type":"number","step":"0.01","label":_vm.$t('PROPERTIES_PAYMENTS.REST_TO_PAY'),"disabled":true,"value":parseFloat(item.restToPay).toFixed(2)}})],1):_vm._e(),_c('div',{staticClass:"form-group-item name"},[_c('base-input',{attrs:{"type":"number","step":"0.01","label":_vm.$t('PROPERTIES_PAYMENTS.AMOUNT'),"placeholder":_vm.$t('PROPERTIES_PAYMENTS.AMOUNT')},on:{"keyup":function($event){item.restToPay = parseFloat(item.baseAmount).toFixed(2) - parseFloat(item.amount).toFixed(2);
        _vm.amountsChanged();}},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})],1),_c('div',{staticClass:"remove-properties-button"},[_c('label',{staticClass:"form-control-label mb-4"}),_c('base-button',{staticClass:"form-control btn btn-danger",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return (() => _vm.removePropertyPayment(key)).apply(null, arguments)}}},[_c('i',{staticClass:"fal fa-times"})])],1)])}),_c('div',{staticClass:"properties-wrapper"},[(_vm.totalRestToPay > 0)?_c('div',{staticClass:"form-group-item type",staticStyle:{"margin-top":"10px","width":"30%"}},[_c('base-input',{attrs:{"type":"number","step":"0.01","label":_vm.$t('PROPERTIES_PAYMENTS.TOTAL_DU'),"disabled":true,"value":parseFloat(_vm.totalRestToPay).toFixed(2)}})],1):_vm._e()]),_c('base-button',{staticClass:"btn add",attrs:{"type":"button","disabled":_vm.disabled || _vm.totalRestToPay > 0},on:{"click":function($event){$event.preventDefault();return _vm.addPropertyPayment.apply(null, arguments)}}},[_c('i',{staticClass:"far fa-plus"})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }