<template>
  <div class="form-group properties-selector">
    <div
      v-for="(item, key) in propertiesPaymentsModel"
      :key="key"
      class="properties-wrapper"
    >
      <div class="form-group-item type">
        <label class="form-control-label">
          {{ $t("PROPERTIES_PAYMENTS.PROPERTY") }}
        </label>
        <property-selector
          :property="item.property.id?.toString()"
          :filterOrganization="filterOrganization"
          :onlyNotPaied="true"
          v-if="
            !filterProperty &&
            $currentUserCan($permissions.PERM_VIEW_ANY_PROPERTIES)
          "
          @propertyChanged="
            (property) => {
              item.property.id = property.id;
              item.baseAmount = parseFloat(property.total-property.total_paied).toFixed(2);
              item.amount = item.amount == 0 ? parseFloat(property.total-property.total_paied).toFixed(2) : item.amount;
              propertiesPaymentsChanged();
              //item.restToPay = item.refund ? item.amount : ((property.total-property.total_paied)-item.amount).toFixed(2);
              //propertiesPaymentsChanged();
            }
          "
        />
      </div>
      <div class="form-group-item type" v-if="item.amount > 0">
        <base-input
          type="number"
          step="0.01"
          :label="$t('PROPERTIES_PAYMENTS.REST_TO_PAY')"
          :disabled="true"
          :value="parseFloat(item.restToPay).toFixed(2)"
        />
      </div>
      <div class="form-group-item name">
        <base-input
          type="number"
          step="0.01"
          :label="$t('PROPERTIES_PAYMENTS.AMOUNT')"
          :placeholder="$t('PROPERTIES_PAYMENTS.AMOUNT')"
          v-model="item.amount"
          @keyup="
          item.restToPay = parseFloat(item.baseAmount).toFixed(2) - parseFloat(item.amount).toFixed(2);
          amountsChanged();
          "
        />
      </div>
      <div class="remove-properties-button">
        <label class="form-control-label mb-4"></label>
        <base-button
          type="button"
          class="form-control btn btn-danger"
          @click.prevent="() => removePropertyPayment(key)"
        >
          <i class="fal fa-times"></i>
        </base-button>
      </div>
    </div>
    <div class="properties-wrapper">
      <div class="form-group-item type" v-if="totalRestToPay > 0" style="margin-top: 10px;width: 30%;">
        <base-input
          type="number"
          step="0.01"
          :label="$t('PROPERTIES_PAYMENTS.TOTAL_DU')"
          :disabled="true"
          :value="parseFloat(totalRestToPay).toFixed(2)"
        />
      </div>
    </div>
    <base-button
      type="button"
      class="btn add"
      :disabled="disabled || totalRestToPay > 0"
      @click.prevent="addPropertyPayment"
    >
      <i class="far fa-plus"></i>
    </base-button>
  </div>
</template>

<script>
import { Option, Select } from "element-ui";
import { cloneDeep } from "lodash";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import PropertySelector from "@/components/PropertySelector.vue";

export default {
  name: "properties-amounts-inputs",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    BaseInput,
    PropertySelector,
  },

  props: {
    propertiesPaymentsData: {
      type: Array,
      required: true,
      default: () => [],
    },
    paymentId:{
      type: String,
      default: null,
      description: "Payment id"
    },
    paymentAmount:{
      type: String,
      default: null,
      description: "Payment amount"
    },
    totalDu:{
      type: String,
      default: null,
      description: "total Du"
    },
    label: {
      type: String,
      default: null,
      description: "Label of field",
    },
    filterProperty: {
      type: String,
      default: null,
      description: "property id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
  },

  data() {
    return {
      propertiesPaymentsModel: cloneDeep(this.propertiesPaymentsData),
      totalRestToPay: 0,
    };
  },

  setup() {},

  created() {
    this.$root.$on("handleRefundAmount", ({ refundAmount, addPayment }) => {
      if(addPayment){
        this.propertiesPaymentsModel.push({
          property: { type: "properties", id: null },
          amount: refundAmount,
          refund: true,
          type: "properties-payments-properties",
        });
      }else{
        let totalRefund = parseFloat(this.propertiesPaymentsModel[this.propertiesPaymentsModel.length - 1].amount) + refundAmount;
        this.propertiesPaymentsModel[this.propertiesPaymentsModel.length - 1].amount = totalRefund;
      }
      this.propertiesPaymentsChanged();
    });
    this.$root.$on("handleTotalAmount", ({ totalAmount }) => {
      this.propertiesPaymentsModel.forEach((property) => {
        property.amount = totalAmount > parseFloat(property.amount) ? parseFloat(property.amount) : parseFloat(totalAmount).toFixed(2);
        totalAmount = totalAmount - parseFloat(property.amount) > 0 ? totalAmount - parseFloat(property.amount) : parseFloat(0);
      });
      this.propertiesPaymentsModel = this.propertiesPaymentsModel.filter(item => parseFloat(item.amount) !== 0);
      this.propertiesPaymentsChanged();
    });
    if(this.paymentId){
      this.totalRestToPay = parseFloat(this.totalDu).toFixed(2);
    }
  },

  methods: {
    addPropertyPayment() {
      this.propertiesPaymentsModel.push({
        property: { type: "properties", id: null },
        amount: 0,
        restToPay: 0,
        baseAmount: 0,
        refund: false,
        type: "properties-payments-properties",
      });
      this.propertiesPaymentsChanged();
    },

    removePropertyPayment(key) {
      this.propertiesPaymentsModel = this.propertiesPaymentsModel.filter(
        (_, idx) => idx !== key
      );
      this.propertiesPaymentsChanged();
    },

    propertiesPaymentsChanged() {
      let amount = parseFloat(this.paymentAmount).toFixed(2);
      this.totalRestToPay = 0;
      const propertiesPaymentsModelData = cloneDeep(
        this.propertiesPaymentsModel
      ).map((item) => {
        let propertyAmount = amount - parseFloat(item.amount).toFixed(2) > 0 ? parseFloat(item.amount).toFixed(2) : parseFloat(amount).toFixed(2);
        let propertyRestToPay = parseFloat(item.baseAmount).toFixed(2) - parseFloat(propertyAmount).toFixed(2) > 0 ? parseFloat(item.baseAmount).toFixed(2) - parseFloat(propertyAmount).toFixed(2) : 0;
        amount = amount - parseFloat(item.baseAmount).toFixed(2) > 0 ?amount - parseFloat(item.baseAmount).toFixed(2) : parseFloat(amount).toFixed(2);
        this.totalRestToPay = this.totalRestToPay + propertyRestToPay;
        return { ...item, amount : parseFloat(propertyAmount).toFixed(2), restToPay: parseFloat(propertyRestToPay).toFixed(2) };
      });
      this.$emit("propertiesPaymentsChanged", propertiesPaymentsModelData);
    },
    amountsChanged(){
      const propertiesPaymentsModelData = cloneDeep(
        this.propertiesPaymentsModel
      )
      this.$emit("amountsChanged", propertiesPaymentsModelData);
    },
  },

  watch: {
    propertiesPaymentsData(propertiesPaymentsData) {
      this.propertiesPaymentsModel = cloneDeep(this.propertiesPaymentsData);
    },
  },
};
</script>

<style lang="scss">
.form-group {
  &.properties-selector {
    > label {
      display: block;
      font-size: 16px;
      margin-bottom: 10px;
    }

    .form-group-item {
      flex: 1;
      padding: 0 10px;
      .el-select {
        margin-top: 8px;
      }
      &.remove {
        max-width: 53px;
      }
    }

    .remove-properties-button {
      padding-left: 10px;
      button {
        box-shadow: none;
        border: none;
        margin: 0;
        &.btn-danger {
          width: 43px;
          height: 43px;
          border-radius: 8px;
          background-color: #f5365c;
          margin-bottom: 0;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          i {
            font-size: 20px;
            color: #fff;
          }
        }
      }
    }

    button {
      &.add {
        width: 43px;
        height: 43px;
        border-radius: 8px;
        background-color: #000;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 15px 0 0 0;
        i {
          font-size: 18px;
          color: #fff;
        }
      }
    }
  }

  .properties-wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 10px 0 0 0;

    .form-group-item {
      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    &:first-child {
      margin-top: 15px;
    }

    .form-group {
      margin-bottom: 0;
    }
  }
}
</style>
