<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("PROPERTIES_PAYMENTS.PROPERTIES_PAYMENTS_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openPropertiesPaymentCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_PROPERTIES_PAYMENTS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{
                $t("PROPERTIES_PAYMENTS.ADD_PROPERTIES_PAYMENT")
              }}</span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_PROPERTIES_PAYMENTS)"
              :objectType="'propertiesPayments'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <properties-payment-list-table
        @onViewPropertiesPayment="openPropertiesPaymentViewModal"
        @onEditPropertiesPayment="openPropertiesPaymentEditModal"
        @onDeletePropertiesPayment="deletePropertiesPayment"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewPropertiesPaymentModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewPropertiesPaymentModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'PROPERTIES_PAYMENT_VIEW'"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PROPERTIES_PAYMENTS.VIEW_PROPERTIES_PAYMENT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openPropertiesPayment"
                :objectType="'propertiesPayments'"
                :objectId="openPropertiesPayment.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="edit" @click="openPropertiesPaymentEditModal(openPropertiesPayment)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="edit" @click="openReceiptModal(openPropertiesPayment)">
                  <i class="fal fa-file"></i>
                  <span>{{ $t("PROPERTIES_PAYMENTS.RECEIPT") }}</span>
                </button>
                <button class="edit" @click="deletePropertiesPayment(openPropertiesPayment)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closePropertiesPaymentModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-properties-payment-page v-if="openPropertiesPayment" :propertiesPaymentId="openPropertiesPayment.id" />
          </div>
        </div>
      </div>

      <div
        v-if="isEditPropertiesPaymentModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditPropertiesPaymentModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'PROPERTIES_PAYMENTS'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("PROPERTIES_PAYMENTS.EDIT_PROPERTIES_PAYMENT") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="view" @click="openPropertiesPaymentViewModal(openPropertiesPayment)">
                  <i class="fal fa-eye"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closePropertiesPaymentModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-properties-payment-page
              v-if="openPropertiesPayment"
              :propertiesPaymentId="openPropertiesPayment.id"
              @onViewPropertiesPayment="openPropertiesPaymentViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddPropertiesPaymentModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddPropertiesPaymentModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'PROPERTIES_PAYMENT'">
          <div class="resizable-wrapper-content-header">
            <h1>
              {{ $t("PROPERTIES_PAYMENTS.ADD_PROPERTIES_PAYMENT") }}
            </h1>
            <button class="close" @click="closePropertiesPaymentModal">
              <i class="fal fa-times"></i>
            </button>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-properties-payment-page @onViewPropertiesPayment="openPropertiesPaymentViewModal" />
          </div>
        </div>
      </div>
      <modal :show="showReceiptModal" modal-classes="modal-secondary" size="sm">
        <div class="modal-header">
          <h3 class="text-center">
            {{ $t("PROPERTIES_PAYMENTS.RECEIPT") }}
          </h3>
          <button
              type="button"
              class="btn btn-link ml-auto"
              @click="closeReceiptModal"
            >
              <i class="fa fa-times"></i>
          </button>
        </div>

          <div class="form-wrapper">
            <base-input
              :label="`${$t('PROPERTIES_PAYMENTS.EMAIL')}`"
              :placeholder="$t('PROPERTIES_PAYMENTS.EMAIL')"
              v-model="dataReceipt.email"
              @change="onFormChanged()"
            />
          </div>
        

        <div class="text-center">
          <base-button
              @click="print"
              type="button"
              class="elite-button btn-success text-right"
          >
            <span class="btn-inner--icon mr-1">
              <i class="fa fa-print"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("PROPERTIES_PAYMENTS.PRINT_RECEIPT") }}
            </span>
          </base-button>
          <base-button
              @click="sendMailReceipt"
              type="button"
              class="elite-button btn-success text-right"
          >
            <span class="btn-inner--icon mr-1">
              <i class="fa fa-envelope"></i>
            </span>
            <span class="btn-inner--text">
              {{ $t("PROPERTIES_PAYMENTS.SEND_MAIL_RECEIPT") }}
            </span>
          </base-button>
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import PropertiesPaymentListTable from "./partials/PropertiesPaymentListTable.vue";
import EditPropertiesPaymentPage from "./components/EditPropertiesPaymentComponent.vue";
import AddPropertiesPaymentPage from "./components/AddPropertiesPaymentComponent.vue";
import ViewPropertiesPaymentPage from "./components/ViewPropertiesPaymentComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    PropertiesPaymentListTable,
    NotificationSubscription,
    EditPropertiesPaymentPage,
    AddPropertiesPaymentPage,
    ViewPropertiesPaymentPage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const propertiesPaymentId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewPropertiesPaymentModalOpened = false;
    let isEditPropertiesPaymentModalOpened = false;
    let isAddPropertiesPaymentModalOpened = false;
    let openPropertiesPayment = null;
    if (propertiesPaymentId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewPropertiesPaymentModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditPropertiesPaymentModalOpened = true;
      }
      openPropertiesPayment = { id: propertiesPaymentId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddPropertiesPaymentModalOpened = true;
    }
    return {
      isViewPropertiesPaymentModalOpened: isViewPropertiesPaymentModalOpened,
      isEditPropertiesPaymentModalOpened: isEditPropertiesPaymentModalOpened,
      isAddPropertiesPaymentModalOpened: isAddPropertiesPaymentModalOpened,
      openPropertiesPayment: openPropertiesPayment,
      renderKey: 1,
      showReceiptModal: false,
      dataReceipt: {
        email: "",
        attention: "",
        amount: null,
        excerpt: "",
        date: null,
        ids: []
      },
      payment: null,
    };
  },

  methods: {
    openReceiptModal(){
      this.showReceiptModal = true;
    },
    closeReceiptModal(){
      this.showReceiptModal = false;
    },
    async print() {
      this.loading = true;
      swal.fire({
        title: this.$t("COMMON.LOADING"),
        onBeforeOpen: () => {
          swal.showLoading();
        },
      });
      try {
        const response = await this.$store.dispatch(
          "propertiesPayments/print",
          this.openPropertiesPayment.id
        );
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        window.open(url, "_blank");
        swal.close();
        this.loading = false;
      } catch (error) {
        if (error.response) {
          if (error?.response?.status === 404) {
            this.$notify({
              type: "danger",
              message: this.$t("ERRORS.NO_PROPERTY_FOUND"),
            });
          } else {
            this.$notify({
              type: "danger",
              message: "Oops, something went wrong!",
            });
          }
        }
        swal.close();
        this.showReceiptModal = false;
      }
    },
    async sendMailReceipt(){
      if(this.dataReceipt.email == ""){
        this.$notify({
          type: "danger",
          message: this.$t("PROPERTIES_PAYMENTS.EMPTY_EMAIL"),
        });
        return;
      }
      const confirmation = await swal.fire({
        text: this.$t("PROPERTIES_PAYMENTS.SEND_MAIL_RECEIPT_QUESTION"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          this.showAccountStatusModal = false;
          swal.fire({
            title: `Chargement`,
            onBeforeOpen: () => {
              swal.showLoading();
            },
          });
          await this.$store.dispatch(
            "propertiesPayments/get",
            this.openPropertiesPayment.id
          );
          this.payment = this.$store.getters["propertiesPayments/propertiesPayment"];
          this.dataReceipt.attention = this.payment.attention;
          this.dataReceipt.amount = this.payment.amount;
          this.dataReceipt.date = this.payment.date;
          this.dataReceipt.excerpt = this.payment.excerpt;
          this.dataReceipt.ids.push(this.payment.id);
          await this.$store.dispatch("propertiesPayments/sendMailReceipt", this.dataReceipt);
          this.renderKey++;
          swal.close();
          this.showReceiptModal = false;
          this.$notify({
            type: "success",
            message: this.$t("PROPERTIES_PAYMENTS.SENDED_RECEIPT"),
          });
        }
      } catch (error) {
        this.showAccountStatusModal = false;
        swal.close();
        console.log(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
    openPropertiesPaymentCreateModal() {
      this.closePropertiesPaymentModal();
      this.isAddPropertiesPaymentModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PropertiesPayments",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openPropertiesPaymentViewModal(propertiesPayment, reRender = false) {
      this.closePropertiesPaymentModal();
      this.openPropertiesPayment = propertiesPayment;
      this.isViewPropertiesPaymentModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PropertiesPayments",
          query: { id: this.openPropertiesPayment.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openPropertiesPaymentEditModal(propertiesPayment) {
      this.closePropertiesPaymentModal();
      this.openPropertiesPayment = propertiesPayment;
      this.isEditPropertiesPaymentModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PropertiesPayments",
          query: { id: this.openPropertiesPayment.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closePropertiesPaymentModal() {
      this.isAddPropertiesPaymentModalOpened = false;
      this.isViewPropertiesPaymentModalOpened = false;
      this.isEditPropertiesPaymentModalOpened = false;
      this.openPropertiesPayment = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List PropertiesPayments",
          query: {},
        }).href
      );
    },

    async deletePropertiesPayment(propertiesPayment) {
      const confirmation = await swal.fire({
        title: this.$t("PROPERTIES_PAYMENTS.DELETE_THIS_PROPERTIES_PAYMENT"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("propertiesPayments/destroy", propertiesPayment.id);
          this.renderKey++;
          this.closePropertiesPaymentModal();
          this.$notify({
            type: "success",
            message: this.$t("PROPERTIES_PAYMENTS.PROPERTIES_PAYMENT_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .content-btns-modal{
    text-align: center !important;
  }
  .content-btns-modal a{
    margin-right: 20px;
  }
  .modal-header{
    margin-top: -43px;
    margin-right: -34px;
    margin-bottom: 0px;
    text-align: center;
  }
  .modal-header h3{
    margin-top: 10px;
  }
</style>
