<template>
  <form
    class="add-form"
    ref="profile_form"
    @submit.prevent="handleSubmit"
    @keydown.enter.prevent="() => {}"
  >
    <div class="form-wrapper">
      <base-input
        :label="`${$t('PROPERTIES_PAYMENTS.ATTENTION')}`"
        :placeholder="$t('PROPERTIES_PAYMENTS.ATTENTION')"
        v-model="propertiesPayment.attention"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.attention" />
    </div>
    <div class="form-wrapper">
      <base-input
        :label="`${$t('PROPERTIES_PAYMENTS.EMAIL')}`"
        :placeholder="$t('PROPERTIES_PAYMENTS.EMAIL')"
        v-model="propertiesPayment.email"
        @change="onFormChanged()"
      />
      <validation-error :errors="apiValidationErrors.email" />
    </div>
    <div
      class="form-wrapper"
      v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
    >
      <!-- Organization -->
      <base-input
        :label="`${$t('COMMON.ORGANIZATION')} (*)`"
        :placeholder="$t('COMMON.ORGANIZATION')"
      >
        <organization-selector
          :organization="propertiesPayment.organization.id"
          :filterable="true"
          :showAll="false"
          @organizationChanged="
            (organizationId, organization) => {
              propertiesPayment.organization.id = organizationId;
              propertiesPayment.amount = 0;
              propertiesPayment.propertiesPaymentsProperties = [];
              onAmountChanged();
              onFormChanged();
            }
          "
          :disabled="!!propertiesPayment.id"
        />
      </base-input>
      <validation-error :errors="apiValidationErrors.organization" />
    </div>
    <div class="form-wrapper">
      <base-input
        :label="`${$t('PROPERTIES_PAYMENTS.AMOUNT')} (*)`"
        :placeholder="$t('PROPERTIES_PAYMENTS.AMOUNT')"
        v-model="propertiesPayment.amount"
        @change="onAmountChanged();onFormChanged();"
      />
      <validation-error :errors="apiValidationErrors.amount" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('PROPERTIES_PAYMENTS.SOURCE')} (*)`"
        :placeholder="$t('PROPERTIES_PAYMENTS.SOURCE')"
      >
        <el-select
          :clearable="true"
          class="select-primary pagination-select"
          :placeholder="$t('PROPERTIES_PAYMENTS.SOURCE')"
          v-model="propertiesPayment.source"
          @change="onFormRefundChanged"
        >
          <el-option
            class="select-primary"
            v-for="(item, key) in PROPERTIES_PAYMENTS_SOURCES"
            :key="key"
            :label="$t(`PROPERTIES_PAYMENTS.PAYMENT_SOURCE_${item}`)"
            :value="item"
          >
          </el-option>
        </el-select>
      </base-input>
      <validation-error :errors="apiValidationErrors.source" />
    </div>

    <div class="form-wrapper">
      <base-input
        :label="`${$t('PROPERTIES_PAYMENTS.DATE')} (*)`"
        :placeholder="$t('PROPERTIES_PAYMENTS.DATE')"
        v-model="propertiesPayment.date"
        @change="onFormChanged"
      >
        <flat-picker
          :placeholder="$t('PROPERTIES_PAYMENTS.DATE')"
          :config="{
            allowInput: true,
            enableTime: false,
            type: 'date',
            maxDate: 'today',
            locale: $flatPickrLocale(),
            disable: [
              function (date) {
                return date.getDay() === 0;
              },
            ],
          }"
          class="form-control datepicker"
          v-model="propertiesPayment.date"
          @on-change="onFormChanged()"
        >
        </flat-picker>
      </base-input>
      <validation-error :errors="apiValidationErrors.date" />
    </div>
    <hr />
    <div class="form-wrapper full">
      <h3>{{ $t("COMMON.PROPERTIES") }}</h3>
      <properties-amounts-inputs
        :label="$t('COMMON.PROPERTIES')"
        :disabled="!propertiesPayment.organization.id || propertiesPayment.amount == 0"
        :filterOrganization="propertiesPayment.organization.id"
        :paymentAmount="propertiesPayment.amount.toString()"
        :totalDu="totalDu.toString()"
        :paymentId="propertiesPayment.id"
        :propertiesPaymentsData="propertiesPayment.propertiesPaymentsProperties"
        @amountsChanged="
          (propertiesPaymentsData) => {
            onPaymentChanged(propertiesPaymentsData);
            onFormChanged();
          }
        "
        @propertiesPaymentsChanged="
          (propertiesPaymentsData) => {
            onPaymentChanged(propertiesPaymentsData);
            onFormChanged();
          }
        "
      />
      <validation-error :errors="apiValidationErrors.property" />
    </div>
    <div class="form-wrapper full">
      <base-input
        :label="$t('PROPERTIES_PAYMENTS.EXCERPT')"
        :placeholder="$t('PROPERTIES_PAYMENTS.EXCERPT')"
      >
        <html-editor
          v-model="propertiesPayment.excerpt"
          @change="onFormChanged()"
        >
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />
    </div>
    <div class="submit-wrapper">
      <base-button
        type="button"
        class="btn btn-sm elite-submit"
        native-type="submit"
        :disabled="loading"
      >
        <i class="fas fa-spinner fa-spin" v-if="loading"></i>
        {{
          propertiesPayment.id
            ? $t("PROPERTIES_PAYMENTS.EDIT_PROPERTIES_PAYMENT")
            : $t("PROPERTIES_PAYMENTS.ADD_PROPERTIES_PAYMENT")
        }}
      </base-button>
    </div>
    <modal :show="showModal" modal-classes="modal-secondary" size="lg">
      <div class="modal-header">
        <button
            type="button"
            class="btn btn-link ml-auto"
            @click="closeModal"
          >
            <i class="fa fa-times"></i>
        </button>
      </div>
      <h3 class="text-center">
        {{ $t("PROPERTY_TRANSACTIONS.PAYMENT_OPERATION") }}
      </h3>
      

      <div class="text-center">
        <p>{{ $t("PROPERTIES_PAYMENTS.PAYMENT_AMOUNT_GRATER_TO_SUM") }}{{ $formatCurrency(refundAmount) }}</p>
        <p>{{ $t("PROPERTIES_PAYMENTS.PAYMENT_ANOTHER_PROPERTY_TEXT") }}</p>
        <p>{{ $t("PROPERTIES_PAYMENTS.PAYMENT_REFUND_TEXT") }}</p>
        <button
          type="button"
          class="elite-button add"
          @click="PayAnotherProperty"
          :disabled="loading"
          style="margin-right: 30px;"
        >
          {{ $t("PROPERTIES_PAYMENTS.PAYMENT_ANOTHER_PROPERTY_BTN") }}
        </button>
        <button
          type="button"
          class="btn btn-sm elite-submit"
          @click="goToRefundLastProperty"
          :disabled="loading"
        >
          {{ $t("PROPERTIES_PAYMENTS.PAYMENT_REFUND_BTN") }}
        </button>
      </div>
    </modal>
    <modal :show="showRefundModal" modal-classes="modal-secondary" size="lg">
      <div class="modal-header">
        <button
            type="button"
            class="btn btn-link ml-auto"
            @click="closeRefundModal"
          >
            <i class="fa fa-times"></i>
        </button>
      </div>
      <h3 class="text-center">
        {{ $t("PROPERTIES_PAYMENTS.REFUND_PROPERTY") }}
      </h3>
      

      <div class="text-center">
        <p>{{ $t("PROPERTIES_PAYMENTS.PAYMENT_REFUND_LAST_PROPERTY_TEXT") }} {{ $formatCurrency(refundAmount) }}</p>
        <p>{{ $t("PROPERTIES_PAYMENTS.SELECT_PAYMENT_METHOD") }}</p>
        <div>
          <base-input
            :label="`${$t('PROPERTIES_PAYMENTS.SOURCE')} (*)`"
            :placeholder="$t('PROPERTIES_PAYMENTS.SOURCE')"
          >
            <el-select
              :clearable="true"
              class="select-primary pagination-select"
              :placeholder="$t('PROPERTIES_PAYMENTS.SOURCE')"
              v-model="refundPropertiesPayment.source"
            >
              <el-option
                class="select-primary"
                v-for="(item, key) in PROPERTIES_PAYMENTS_SOURCES"
                :key="key"
                :label="$t(`PROPERTIES_PAYMENTS.PAYMENT_SOURCE_${item}`)"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
        <button
          type="button"
          class="btn btn-sm elite-submit"
          @click="RefundLastProperty"
          :disabled="loading"
        >
          {{ $t("COMMON.VALIDATE") }}
        </button>
      </div>
    </modal>
  </form>
    
</template>

<script>
import swal from "sweetalert2";
import { cloneDeep } from "lodash";
import { Select, Option } from "element-ui";
import formMixin from "@/mixins/form-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import ValidationError from "@/components/ValidationError.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { PROPERTIES_PAYMENTS_SOURCES } from "@/constants/propertiesPayment";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import PropertiesAmountsInputs from "@/components/PropertiesAmountsInputs.vue";
import defaultPropertiesPayment from "../../PropertiesPaymentManagement/defaultPropertiesPayment";

export default {
  layout: "DashboardLayout",

  components: {
    HtmlEditor,
    BaseInput,
    ValidationError,
    OrganizationSelector,
    PropertiesAmountsInputs,
    flatPicker,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  mixins: [formMixin],

  props: ["propertiesPaymentData", "formErrors", "loading"],

  data() {
    let propertiesPaymentData = cloneDeep(this.propertiesPaymentData);
    propertiesPaymentData = this.$fillUserOrganizationData(
      propertiesPaymentData
    );

    let propertiesPaymentModel = cloneDeep(defaultPropertiesPayment);
    return {
      propertiesPayment: propertiesPaymentData,
      PROPERTIES_PAYMENTS_SOURCES,
      showModal: false,
      showRefundModal: false,
      refundAmount: null,
      refundPropertiesPayment: cloneDeep(propertiesPaymentModel),
      totalDu: 0,
    };
  },

  created() {
    if(this.propertiesPayment.id){
      let amount = parseFloat(this.propertiesPayment.amount).toFixed(2);
      this.propertiesPayment.propertiesPaymentsProperties = this.propertiesPayment.propertiesPaymentsProperties.map((item) => {
        let baseAmount = (item.property.total-item.property.total_paied).toFixed(2);
        this.totalDu = parseFloat(this.totalDu) + parseFloat(baseAmount);
      console.log(this.totalDu);
        return { ...item, restToPay: baseAmount };
      });
    }
  },

  mounted() {},

  methods: {
    validateEmail(email) {
      // Regex pour valider l'adresse e-mail
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(email)) {
        return true;
      } else {
        return false;
      }
    },
    PayAnotherProperty(){
      this.$root.$emit("handleRefundAmount", {
        refundAmount: this.refundAmount,
        addPayment: true
      });
      this.closeModal();
    },
    goToRefundLastProperty(){
      this.closeModal();
      this.showRefundModal = true;
      this.refundPropertiesPayment.attention= this.propertiesPayment.attention;
      this.refundPropertiesPayment.email= this.propertiesPayment.email;
      this.refundPropertiesPayment.date= this.propertiesPayment.date;
      this.refundPropertiesPayment.amount= -this.refundAmount;
      this.refundPropertiesPayment.organization= this.propertiesPayment.organization;
      this.refundPropertiesPayment.propertiesPaymentsProperties= this.propertiesPayment.propertiesPaymentsProperties[this.propertiesPayment.propertiesPaymentsProperties.length - 1];
    },
    RefundLastProperty(){
      this.$root.$emit("handleRefundAmount", {
        refundAmount: this.refundAmount,
        addPayment: false
      });
      this.handleSubmit(true);
      this.closeRefundModal();
    },
    closeModal(refresh, payment = null) {
      this.showModal = false;
      if (refresh) {
        this.renderKey++;
      }
    },
    closeRefundModal(refresh, payment = null) {
      this.showRefundModal = false;
      if (refresh) {
        this.renderKey++;
      }
    },
    async handleSubmit(refund = false) {
      let propertiesPaymentData = cloneDeep(this.propertiesPayment);
      if(propertiesPaymentData.attention == ""){
        this.$notify({
          type: "danger",
          message: this.$t("PROPERTIES_PAYMENTS.EMPTY_ATTENTION"),
        });
        return;
      }
      if(propertiesPaymentData.amount == 0){
        this.$notify({
          type: "danger",
          message: this.$t("PROPERTIES_PAYMENTS.EMPTY_AMOUNT"),
        });
        return;
      }
      if(!propertiesPaymentData.date || propertiesPaymentData.date == ""){
        this.$notify({
          type: "danger",
          message: this.$t("PROPERTIES_PAYMENTS.EMPTY_DATE"),
        });
        return;
      }
      if(this.propertiesPaymentData.email != "" && !this.validateEmail(this.propertiesPaymentData.email)){
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.INVALID_EMAIL"),
        });
      }
      propertiesPaymentData = this.$fillUserOrganizationData(
        propertiesPaymentData
      );
      propertiesPaymentData.propertiesPaymentsProperties =
        propertiesPaymentData.propertiesPaymentsProperties.filter((item) => {
          return !!item.property.id && !!item.amount;
        });
      propertiesPaymentData.amount = parseFloat(propertiesPaymentData.amount);
      if (propertiesPaymentData.propertiesPaymentsProperties.length == 0) {
        await swal.fire({
          title: this.$t("ERRORS.NO_PROPERTY_SELECTED"),
          icon: "error",
          confirmButtonText: this.$t("COMMON.OK"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        return;
      }

      const totalProperiesAmount =
        propertiesPaymentData.propertiesPaymentsProperties.reduce(
          (sum, item) => parseFloat(sum) + parseFloat(item.amount),
          0
        );
      if (parseFloat(totalProperiesAmount).toFixed(2) != parseFloat(propertiesPaymentData.amount).toFixed(2)) {
        if(totalProperiesAmount < propertiesPaymentData.amount){
          this.refundAmount = parseFloat(propertiesPaymentData.amount) - parseFloat(totalProperiesAmount);
          this.showModal = true;
          return;
        }
      }
      if(this.refundAmount > 0){
        this.$emit("propertiesPaymentSubmitted", propertiesPaymentData, this.refundPropertiesPayment);
      }else{
        this.$emit("propertiesPaymentSubmitted", propertiesPaymentData);
      }
    },

    onFormChanged() {
      this.$emit("formChanged");
    },
    onFormRefundChanged() {
      this.$emit("onFormRefundChanged");
    },
    onPaymentChanged(properties){
      /*const total = properties.reduce((sum, property) => sum + (property.amount || 0), 0);
      this.propertiesPayment.amount = parseFloat(total) > parseFloat(this.propertiesPayment.amount) ? total.toFixed(2) : parseFloat(this.propertiesPayment.amount).toFixed(2);*/
      this.propertiesPayment.propertiesPaymentsProperties = properties;
    },
    onAmountChanged(){
      let total = 0;
      this.propertiesPayment.propertiesPaymentsProperties.forEach((item) => {
        let propertyAmount = parseFloat(item.amount) + parseFloat(item.restToPay);
        total = total + propertyAmount;
      });
      const amount = parseFloat(this.propertiesPayment.amount).toFixed(2);
      if(amount <= total){
        this.$root.$emit("handleTotalAmount", {
          totalAmount: amount,
        });
      }
    }
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    propertiesPaymentData(propertiesPaymentData) {
      if (propertiesPaymentData) {
        this.propertiesPayment = {
          ...this.propertiesPayment,
          ...cloneDeep(propertiesPaymentData),
        };
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .content-btns-modal{
    text-align: center !important;
  }
  .content-btns-modal a{
    margin-right: 20px;
  }
  .modal-header{
    margin-top: -43px;
    margin-right: -50px;
    margin-bottom: -30px;
  }
</style>
