<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!propertiesPayment">
      <span class="loader"></span>
    </span>
    <properties-payment-form
      v-if="propertiesPayment"
      :loading="loading"
      :propertiesPaymentData="propertiesPayment"
      :formErrors="formErrors"
      @propertiesPaymentSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import PropertiesPaymentForm from "../partials/PropertiesPaymentForm.vue";

export default {
  layout: "DashboardLayout",

  components: { PropertiesPaymentForm },

  mixins: [alertLeave],

  props: {
    propertiesPaymentId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      propertiesPayment: null,
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch(
          "propertiesPayments/get",
          this.propertiesPaymentId
        );
        this.propertiesPayment =
          this.$store.getters["propertiesPayments/propertiesPayment"];
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    async handleSubmit(propertiesPayment, refundPropertyPayment = null) {
      this.loading = true;
      const propertiesPaymentData = cloneDeep(propertiesPayment);
      const propertiesPaymentPropertiesData = cloneDeep(
        propertiesPayment.propertiesPaymentsProperties
      );

      delete propertiesPaymentData.propertiesPaymentsProperties;

      try {
        await this.$store.dispatch(
          "propertiesPayments/update",
          propertiesPaymentData
        );
        const propertiesPayment =
          this.$store.getters["propertiesPayments/propertiesPayment"];

        for (const propertiesPaymentProperty of this.propertiesPayment
          .propertiesPaymentsProperties) {
          await this.$store.dispatch(
            "propertiesPaymentsProperties/destroy",
            propertiesPaymentProperty.id
          );
        }

        for (const propertiesPaymentProperty of propertiesPaymentPropertiesData) {
          const propertiesPaymentPropertyData = {
            ...propertiesPaymentProperty,
            propertiesPayment: propertiesPayment,
            relationshipNames: ["property", "propertiesPayment"],
          };
          delete propertiesPaymentPropertyData.id;
          await this.$store.dispatch(
            "propertiesPaymentsProperties/add",
            propertiesPaymentPropertyData
          );
        }

        // remboursement
        if(refundPropertyPayment){
          const refundPropertyPaymentData = cloneDeep(refundPropertyPayment);
          const property = {
            id : refundPropertyPaymentData.propertiesPaymentsProperties.property.id,
            type : "properties"
          };
          delete refundPropertyPaymentData.propertiesPaymentsProperties;

          await this.$store.dispatch(
            "propertiesPayments/add",
            refundPropertyPaymentData
          );
          const refundPayment =
            this.$store.getters["propertiesPayments/propertiesPayment"];

          await this.$store.dispatch("propertiesPaymentsProperties/add", {
            type: "properties-payments-properties",
            amount: refundPropertyPaymentData.amount,
            property: property,
            propertiesPayment: refundPayment,
            relationshipNames: ["property", "propertiesPayment"],
          });
        }

        this.$notify({
          type: "success",
          message: this.$t("PROPERTIES_PAYMENTS.PROPERTIES_PAYMENT_UPDATED"),
        });

        this.$emit("onViewPropertiesPayment", propertiesPayment, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        if (error.response) {
          this.formErrors = error.response.data.errors;
        }
        this.loading = false;
      }
    },
  },
};
</script>
